import { Link } from "gatsby"
import React from "react"
import LayoutAlt from "../../components/LayoutAlt"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/seo"

export default function AssetIntegrity() {
  return (
    <LayoutAlt>
      <Seo
        title="Asset Integrity"
        description="CAST Rope Access is a South African turnkey rope access service
              provider to the Asset Integrity sector."
        pathname="/capabilities/asset-integrity"
      />
      <section className="hero hero--asset-integrity px-4 bg-brand-blue flex items-center relative">
        <StaticImage
          className="absolute top-0 right-0 bottom-0 left-0 z-0 h-full w-full object-cover"
          src="../../images/hero-asset-integrity-v2.jpg"
          alt="Asset Integrity"
          placeholder="blurred"
        />
        <div className="container mx-auto relative z-10">
          <div className="hero__content text-center">
            <h1 className="text-white mx-auto mb-6">Asset Integrity</h1>
            <p className="intro text-white lg:text-lg font-semibold tracking-wider mx-auto mb-8 lg:w-1/2">
              CAST Rope Access is a South African turnkey rope access service
              provider to the Asset Integrity sector.
            </p>
            <Link className="btn btn--asset-integrity" to="/contact-us">
              Contact Us
            </Link>
          </div>
        </div>
      </section>
      <section className="capability-details px-4 py-24">
        <div className="container mx-auto md:grid md:grid-cols-2 md:gap-8">
          <div className="capability-details__item">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <StaticImage
                className="border-4 border-capability-purple shadow-xl rounded-xl"
                src="../../images/asset-integrity-01.jpg"
                alt="Asset Integrity"
                placeholder="blurred"
              />
              <StaticImage
                className="border-4 border-capability-purple shadow-xl rounded-xl"
                src="../../images/asset-integrity-02.jpg"
                alt="Asset Integrity"
                placeholder="blurred"
              />
            </div>
            <h3 className="text-capability-purple mb-6">
              RTG, STS & BULK LOADERS
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>UAV Inspections.</li>
              <li>Close up Visual Inspections.</li>
              <li>Detailed Visual</li>
              <li>Inspections - Structural Integrity.</li>
              <li>Steel Replacement (Cutting &amp; Welding).</li>
              <li>
                All lifts are planned and assessed prior commencement and
                detailed risk assessment procedures (RAPS) are followed to
                ensure lifts are completed safely and once only.
              </li>
              <li>Bolt Torquing/Replacement.</li>
            </ul>
            <span className="mb-6 border-b-2 border-capability-purple inline-block h-1 w-16"></span>
          </div>
          <div className="capability-details__item">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <StaticImage
                className="border-4 border-capability-purple shadow-xl rounded-xl"
                src="../../images/asset-integrity-03.jpg"
                alt="Asset Integrity"
                placeholder="blurred"
              />
              <StaticImage
                className="border-4 border-capability-purple shadow-xl rounded-xl"
                src="../../images/asset-integrity-04.jpg"
                alt="Asset Integrity"
                placeholder="blurred"
              />
            </div>
            <h3 className="text-capability-purple mb-6">
              PIPE RACK INSPECTION &amp; REPAIRS
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>UAV Inspections.</li>
              <li>lose up Visual Inspections.</li>
              <li>Detailed Visual Inspections.</li>
              <li>NDT Inspections - Structural Integrity.</li>
              <li>Insulation Repairs &Replacement.</li>
              <li>Bolt Torquing/Replacement.</li>
            </ul>
            <span className="mb-6 border-b-2 border-capability-purple inline-block h-1 w-16"></span>
          </div>
          <div className="capability-details__item">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <StaticImage
                className="border-4 border-capability-purple shadow-xl rounded-xl"
                src="../../images/asset-integrity-05.jpg"
                alt="Asset Integrity"
                placeholder="blurred"
              />
              <StaticImage
                className="border-4 border-capability-purple shadow-xl rounded-xl"
                src="../../images/asset-integrity-06.jpg"
                alt="Asset Integrity"
                placeholder="blurred"
              />
            </div>
            <h3 className="text-capability-purple mb-6">
              WHARF &amp; JETTY REPAIRS
            </h3>
            <ul className="list-disc pl-6 mb-6">
              <li>Close up Visual Inspections.</li>
              <li>Detailed Visual Inspections.</li>
              <li>Structural Integrity Survey.</li>
              <li>Spalling Repairs.</li>
              <li>Bolt Torquing/Replacement.</li>
              <li>Surface repair and prep.</li>
            </ul>
            <span className="mb-6 border-b-2 border-capability-purple inline-block h-1 w-16"></span>
          </div>
        </div>
        <div className="text-center">
          <Link className="btn btn--asset-integrity" to="/contact-us">
            Contact Us
          </Link>
        </div>
      </section>
    </LayoutAlt>
  )
}
